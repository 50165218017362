import { RouteRecordRaw } from "vue-router";

import auth from "@/middleware/auth.middleware";
import accessAuth from "@/middleware/accessAuth.middleware"; // denegar acceso a invitados a cierta ruta

const equipoRoutes: Array<RouteRecordRaw> = [
  {
    path: "/bitacora",
    name: "bitacora",
    beforeEnter: [auth],
    component: () => import("@/views/crafted/pages/bitacoras/index.vue"),
  },
  {
    path: "/bitacora/ver/:id",
    name: "bitacora.ver",
    beforeEnter: [auth],
    component: () =>
      import("@/views/crafted/pages/bitacoras/forms/ver/index.vue"),
  },
  {
    path: "/bitacora/responder/:id",
    name: "bitacora.responder",
    beforeEnter: [auth],
    component: () =>
      import("@/views/crafted/pages/bitacoras/forms/responder/index.vue"),
  },
];

export default equipoRoutes;
