import { RouteRecordRaw } from "vue-router";

import auth from "@/middleware/auth.middleware";

const equipoRoutes: Array<RouteRecordRaw> = [
  {
    path: "/perfil",
    name: "perfil",
    beforeEnter: [auth],
    component: () => import("@/views/crafted/pages/perfil/index.vue"),
  },
];

export default equipoRoutes;
