import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import router from "@/router/clean";
import store from "@/storeTs";
import { Actions } from "@/storeTs/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

/**
 * @description Request interceptor
 * @param {App} app
 */
axios.interceptors.request.use(async (request) => {
  const token = store.getters.hasToken;

  // show page loading
  await store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

/**
 * @description Response interceptor
 * @param {App} app
 */
axios.interceptors.response.use(
  async (response) => {
    await store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    return response;
  },
  async (error) => {
    await store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    const { status } = error.response;
    if (status === 401 && store.getters.isUserAuthenticated) {
      store.commit(Actions.LOGOUT);
      router.push({ name: "sign-in" });
      Swal.fire({
        icon: "warning",
        title: "Mensaje",
        text: "La sesión ha expirado",
      });
    } else if (status === 403) {
      store.commit(Actions.LOGOUT);
      router.push({ name: "sign-in" });
      Swal.fire({
        icon: "warning",
        title: "Mensaje",
        text: "La sesión ha expirado",
      });
    } else if (status === 429) {
      Swal.fire({
        title: "Mensaje de error",
        icon: "error",
        text: "Demasiados ventanas abiertas, intente más tarde",
      });
    } else if (status >= 400) {
      Swal.fire({
        title: "Mensaje de error",
        icon: "error",
        text: error.response.data.message,
      });
    } else if (status >= 500) {
      await serverError(error.response);
    }

    return Promise.reject(error);
  }
);

let serverErrorModalShown = false;
async function serverError(response) {
  if (serverErrorModalShown) {
    return;
  }

  if ((response.headers["content-type"] || "").includes("text/html")) {
    const iframe = document.createElement("iframe");

    if (response.data instanceof Blob) {
      iframe.srcdoc = await response.data.text();
    } else {
      iframe.srcdoc = response.data;
    }

    Swal.fire({
      html: iframe.outerHTML,
      showConfirmButton: false,
      customClass: { container: "server-error-modal" },
      didDestroy: () => {
        serverErrorModalShown = false;
      },
      grow: "fullscreen",
      padding: 0,
    });

    serverErrorModalShown = true;
  }
}

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    // ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @param params
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(
      `${resource}${slug ? "/" + slug : ""}`,
      params
    );
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
