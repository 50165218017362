import { RouteRecordRaw } from "vue-router";
import store from "@/storeTs";
import accessAuth from "@/middleware/accessAuth.middleware";

const invitadoRoutes: Array<RouteRecordRaw> = [
  {
    path: "/invitados",
    name: "invitados",
    beforeEnter: [accessAuth],
    component: () => import("@/views/crafted/pages/invitados/index.vue"),
  },
  {
    path: "/invitados/create",
    name: "invitados.create",
    beforeEnter: [accessAuth],
    component: () =>
      import("@/views/crafted/pages/invitados/forms/create/index.vue"),
  },
  {
    path: "/invitados/edit/:id",
    name: "invitados.edit",
    beforeEnter: [accessAuth],
    component: () =>
      import("@/views/crafted/pages/invitados/forms/edit/index.vue"),
  },
];

export default invitadoRoutes;
