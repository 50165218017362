import { RouteRecordRaw } from "vue-router";

import auth from "@/middleware/auth.middleware";

const equipoRoutes: Array<RouteRecordRaw> = [
  {
    path: "/tutorial",
    name: "tutorial",
    beforeEnter: [auth],
    component: () => import("@/views/crafted/pages/mesaAyuda/index.vue"),
  },
];

export default equipoRoutes;
