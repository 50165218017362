import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/storeTs";
import { Mutations, Actions } from "@/storeTs/enums/StoreEnums";
import auth from "@/middleware/auth.middleware";
import checkAuth from "@/middleware/checkAuth.middleware";

import authRouter from "@/views/crafted/authentication/router";
import equiposRouter from "@/views/crafted/pages/equipos/router";
import bitacoraRouter from "@/views/crafted/pages/bitacoras/router";
import informesRouter from "@/views/crafted/pages/informes/router";
import perfilRouter from "@/views/crafted/pages/perfil/router";
import invitadosRouter from "@/views/crafted/pages/invitados/router";
import ubicacionesRouter from "@/views/crafted/pages/ubicaciones/router";
import mesaAyudaRouter from "@/views/crafted/pages/mesaAyuda/router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/equipos",
    beforeEnter: [auth],
    component: () => import("@/layout/Layout.vue"),
    children: [
      /*{
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },*/
      ...equiposRouter,
      ...bitacoraRouter,
      ...informesRouter,
      ...perfilRouter,
      ...invitadosRouter,
      ...ubicacionesRouter,
      ...mesaAyudaRouter,
    ],
  },
  ...authRouter,
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    // the 401 route, when the user haven't access to the page
    path: "/401",
    name: "401",
    component: () => import("@/views/crafted/authentication/Error401.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(checkAuth);
router.beforeEach(async () => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  await store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
