import store from "../storeTs";

export default async (to, from, next) => {
  console.log("checkAuth.middleware.js", store.getters.isUserAuthenticated);
  if (!store.getters.isUserAuthenticated) {
    next({ name: "sign-in" });
  } else {
    next();
  }
};
