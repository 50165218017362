import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/storeTs/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import jwt_decode, { JwtPayload } from "jwt-decode";
import dayjs from "dayjs";

export interface User {
  _id: string;
  razonSocial: string;
  nit: string;
  correo: string;
  clave: string;
  createAt: string;
  updateAt: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
  token: unknown;
  refreshToken: unknown;
  refreshTask: unknown;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  token = JwtService.getToken() as string;
  refreshToken = JwtService.getRefreshToken() as string;
  refreshTask = 0 as number;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get hasToken(): string {
    return this.token;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    // console.log("SET_AUTH ---> ", user);
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_TOKEN](payload) {
    // console.log("SET TOKEN ---> ", payload);
    this.token = payload.token;
    this.refreshToken = payload.refreshToken;
    JwtService.saveToken(payload.token);
    JwtService.saveRefreshToken(payload.refreshToken);
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.clave = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.REFRESH_TASKS](refreshTask) {
    // console.log("REFRESH_TASKS ---> ", refreshTask);
    if (this.refreshTask) clearInterval(this.refreshTask);
    this.refreshTask = refreshTask;
  }

  @Action
  [Actions.REFRESH_TOKENS]() {
    // console.log("REFRESH_TOKENS ---> ");
  }

  @Action
  [Actions.AUTO_REFRESH]() {
    const token = this.token;
    const { exp } = jwt_decode<JwtPayload>(token || "") || null;
    const now = dayjs(new Date());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const fechaExp = dayjs(new Date(exp * 1000));
    let timeUntilRefresh = fechaExp.diff(now, "s");
    timeUntilRefresh -= 60;
    // console.log("timeUntilRefresh: ", timeUntilRefresh);
    if (timeUntilRefresh <= 0) return false;

    const refreshTask = setTimeout(
      () => this.context.dispatch(Actions.REFRESH_TOKENS),
      timeUntilRefresh * 1000
    );
    this.context.commit(Mutations.REFRESH_TASKS, refreshTask);
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post(
      `${process.env.VUE_APP_MICROSERVICE_API_SECURITY}/auth/login`,
      credentials
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TOKEN, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      await ApiService.get(
        `${process.env.VUE_APP_MICROSERVICE_API_SECURITY}/auth/me`,
        ""
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
