import { RouteRecordRaw } from "vue-router";

import auth from "@/middleware/auth.middleware";
import accessAuth from "@/middleware/accessAuth.middleware";

const ubicacionesRouter: Array<RouteRecordRaw> = [
  {
    path: "/ubicaciones",
    name: "ubicaciones",
    beforeEnter: [accessAuth],
    component: () => import("@/views/crafted/pages/ubicaciones/index.vue"),
  },
  {
    path: "/ubicaciones/create",
    name: "ubicaciones.create",
    beforeEnter: [accessAuth],
    component: () =>
      import("@/views/crafted/pages/ubicaciones/forms/create/index.vue"),
  },
  {
    path: "/ubicaciones/edit/:id",
    name: "ubicaciones.edit",
    beforeEnter: [accessAuth],
    component: () =>
      import("@/views/crafted/pages/ubicaciones/forms/edit/index.vue"),
  },
];

export default ubicacionesRouter;
