import store from "../storeTs";
import { Actions } from "../storeTs/enums/StoreEnums";

export default async (to, from, next) => {
  console.log(
    "Has token: ",
    !!store.getters.hasToken,
    "is authenticated: ",
    store.getters.isUserAuthenticated
  );
  if (!store.getters.isUserAuthenticated && store.getters.hasToken) {
    try {
      await store.dispatch(Actions.VERIFY_AUTH);
    } catch (e) {
      console.error(e);
    }
  }

  next();
};
