import { RouteRecordRaw } from "vue-router";

import auth from "@/middleware/auth.middleware";

const equipoRoutes: Array<RouteRecordRaw> = [
  {
    path: "/informes",
    name: "informes",
    beforeEnter: [auth],
    component: () => import("@/views/crafted/pages/informes/index.vue"),
    children: [
      {
        path: "descargar",
        name: "informes.descargar",
        component: () =>
          import("@/views/crafted/pages/informes/descargar/index.vue"),
        beforeEnter: [auth],
      },
      {
        path: "descargar-bitacora",
        name: "informes.descargar-bitacora",
        component: () =>
          import("@/views/crafted/pages/informes/descargarBitacora/index.vue"),
        beforeEnter: [auth],
      },
      {
        path: "descargar-bitacora-sedes",
        name: "informes.descargar-bitacora-sedes",
        component: () =>
          import("@/views/crafted/pages/informes/bitacoraPorSedes/index.vue"),
        beforeEnter: [auth],
      },
      {
        path: "programables",
        name: "informes.programables",
        beforeEnter: [auth],
        component: () =>
          import("@/views/crafted/pages/informes/programables/index.vue"),
      },
      {
        path: "programables/crear",
        name: "informes.programables.crear",
        beforeEnter: [auth],
        component: () =>
          import(
            "@/views/crafted/pages/informes/programables/form/create/index.vue"
          ),
      },
      {
        path: "programables/editar/:id",
        name: "informes.programables.editar",
        beforeEnter: [auth],
        component: () =>
          import(
            "@/views/crafted/pages/informes/programables/form/edit/index.vue"
          ),
      },
    ],
  },
];

export default equipoRoutes;
